<script setup>
import { resolveComponent, computed } from "vue";
import CommonEmbed from "../common/embed.vue";
import CommonImage from "../common/image.vue";
import CommonVideo from "../common/video.vue";

const emit = defineEmits(["loaded"]);

const loaded = ref(false);

const onLoaded = () => {
  const testing = false; // RE: use this timeout to test simulating slower connections

  if (testing) {
    setTimeout(() => {
      loaded.value = true;
      emit("loaded");
    }, 4000);
  } else {
    loaded.value = true;
    emit("loaded");
  }
};

const props = defineProps({
  data: [Object, null],
  playsinline: [Boolean, false],
  loadingThumbnail: Boolean,
  classes: [String, Array, null],
  sizes: [String, null]
});

const components = {
  gif: CommonImage,
  embed: CommonEmbed,
  image: CommonImage,
  video: CommonVideo,
};

const media = computed(() => {
  if (props.data && Array.isArray(props.data)) return props.data[0];
  return props.data;
});

const componentType = computed(() => {
  return media.value.extension == "json" ? "embed" : media.value.type;
});
</script>

<template>
  <!--
  <div class="media-flag">loaded: {{ loaded }}</div>
  -->

  <component
    v-if="media && componentType == 'embed'"
    :is="components[componentType]"
    v-bind="{ ...media }"
    :data="data"
    :playsinline="playsinline"
    @loaded="onLoaded"
  />

  <component
    v-if="media && componentType != 'embed'"
    :class="[classes, loaded ? 'media-loaded' : 'media-loading']"
    :is="components[componentType]"
    :data="{ ...media, loadingThumbnail }"
    :sizes="sizes"
    :playsinline="playsinline"
    @loaded="onLoaded"
  />
</template>

<style lang="postcss" scoped>
.media-flag {
  position: absolute;
  min-width: 200px;
  min-height: 100px;
  max-width: 500px;
  height: auto;
  color: white;
  font-size: 16px;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  background-color: rgba(255, 20, 20, 0.5);
  text-align: left;
  pointer-events: none;
  z-index: 200;
}

.media-loading {
  opacity: 0;
}

.media-loaded {
  opacity: 1;
  transition: transform 0.3s ease, opacity 0.5s ease;
}

.media-container {
  position: relative;
  margin-left: auto;
  margin-right: auto;

  > * {
    &:not(:first-of-type) {
      padding-left: units(0.5);
    }

    &:not(:last-of-type) {
      padding-right: units(0.5);
    }

    height: 100%;
    width: 100%;
  }
}
</style>
